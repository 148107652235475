import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
//import { strings } from '../../resources/strings';

export class TicketInfo extends Component {
    render() {
        return (
            <Container fluid className="mb-5 tariff">
                <Row className="mt-5">
                    <a href="docs/Tarifas_AVE MOBILIDADE.pdf" target="_blank">Descarregar Informação Tarifária Completa</a>
                </Row>
                <Row className="mt-5">
                    <h1>Temos sempre uma solução a pensar em si.</h1>
                </Row>
                <Row>
                    <Col md={6}>
                        <Row className="mt-5">
                            <h5 class="darkgray">BILHETE <b>SIMPLES A BORDO</b><br />INTEIRO E MEIO</h5>
                            <ul>
                                <li>Título <b>não personalizado;</b></li>
                                <li>Vendido a bordo;</li>
                                <li>Permite a realização de 1 viagem no autocarro onde foi adquirido;</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="red">CARTÃO VALOR</h5>
                            <ul>
                                <li>Título <b>não personalizado;</b></li>
                                <li>Vendido e recarregado na bilheteira;</li>
                                <li>Tem de ser validado em cada viagem efetuada;</li>
                                <li>Permite a realização de viagens em qualquer linha e Origem/Destino da rede.</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="red"><b>PRÉ-COMPRADO</b><br />10 VIAGENS (PC10)</h5>
                            <ul>
                                <li>Título <b>não personalizado;</b></li>
                                <li>Vendido e recarregado na bilheteira;</li>
                                <li>Tem de ser validado em cada viagem efetuada;</li>
                                <li>Permite a realização de 10 viagens na Origem/Destino escolhidos;</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="lightgray">PASSE <b>MENSAL</b></h5>
                            <ul>
                                <li>Título <b>personalizado, pessoal e intransmissível;</b></li>
                                <li>Vendido na Bilheteira;</li>
                                <li>Pode ser recarregado mensalmente na bilheteira ou na rede SaltPay;</li>
                                <li>Válido para o mês e Origem / Destino para o qual foi adquirido com nº de viagens ilimitadas;</li>
                                <li>Tem de ser validado a bordo do autocarro.</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="green">PASSE <b>PESSOA MOBILIDADE<br />CONDICIONADA (PPMC)</b> <small>- PASSAGEIROS</small></h5>
                            <ul>
                                <li>Destinado a pessoas com um grau de incapacidade igual ou superior a 60% e que possuam o Cartão Municipal de Pessoa com Deficiência.</li>
                                <li>Funciona nos mesmos moldes do Passe Mensal (PM), mas com desconto de 50% sobre a tarifa aplicável.</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="blue">PASSE <b>ANTIGO COMBATENTE</b></h5>
                            <ul>
                                <li>Destinado a antigos combatentes e viúvas ou viúvos de antigos combatentes;</li>
                                <li>Documentos necessários:
                                    <ul>
                                        <li>Cartão de Cidadão;</li>
                                        <li>1 Fotografia;</li>
                                        <li>Cartão Antigo Combatente / Cartão de viúva ou viúvo de Antigo Combatente;</li>
                                        <li>Comprovativo de residência na CIM Ave (emissão no Portal das Finanças).</li>
                                    </ul>
                                </li>
                            </ul>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Row className="mt-5">
                            <h5 class="bordeaux">PASSE <b>SÉNIOR</b></h5>
                            <ul>
                                <li>Título <b>personalizado</b>, pessoal e intransmissível;</li>
                                <li>Destinado a <b>pessoas com 65 anos ou mais</b> e que possuam o Cartão Municipal do Idoso ou documento equivalente, dos 8 concelhos que integram a CIM do Ave (Cabeceiras de Baste; Fafe; Guimarães; Mondim de Basto; Póvoa de Lanhoso; Vieira do Minho; Vizela; Vila Nova de Famalicão);</li>
                                <li>É válido em todo o concelho de residência do utilizador.</li>
                                <li>Preço: 14,35€</li>
                            </ul>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="blue">PASSE GRATUITO PARA <b>JOVENS</b></h5>

                            <p>O passe <b>sub18+tp gratuito</b> destina-se a todas as crianças e jovens, dos 4 aos 18 anos (inclusive).</p>
                            <ul>
                                <li>Título <b>Personalizado</b>, pessoal e intransmissível;</li>
                                <li>Requirido/vendido na bilheteira;</li>
                                <li>Pode ser recarregado mensalmente na bilheteira ou na rede SaltPay;</li>
                                <li>Válido para o mês e origem > destino para o qual foi adquirido com nº de viagens ilimitadas;</li>
                                <li>Tem de ser sempre validado a bordo do autocarro;</li>
                                <li>Sem necessidade de renovação anual.</li>
                            </ul>
                            <p><b>Documentos necessários:</b></p>
                            <ul>
                                <li>Requisição IMT;</li>
                                <li>Cartão de Cidadão;</li>
                            </ul>

                            <p>O passe <b>sub23+tp gratuito</b> destina-se a todos os jovens até aos 23 anos.</p>
                            <ul>
                                <li>Título <b>Personalizado</b>, pessoal e intransmissível;</li>
                                <li>Requisitado/vendido na bilheteira;</li>
                                <li>Pode ser recarregado mensalmente na bilheteira ou na rede SaltPay;</li>
                                <li>Válido para o mês e origem > destino para o qual foi adquirido com nº de viagens ilimitadas;</li>
                                <li>Tem de ser sempre validado a bordo do autocarro;</li>
                                <li>Sem necessidade de renovação anual e válido até ao último dia do mês que completa 23 anos.</li>
                            </ul>
                            <p><b>Documentos necessários:</b></p>
                            <ul>
                                <li>Requisição IMT;</li>
                                <li>Cartão de Cidadão;</li>
                            </ul>

                            <p>
                                Caso, para a realização das suas deslocações pendulares casa-escola ou casa-trabalho, tenha necessidade de utilizar um passe que abranja mais do que uma AM ou CIM, deve entregar comprovativo de morada de residência habitual e declaração de matrícula da instituição de ensino ou documento comprovativo do local de trabalho. Os beneficiários da alínea anterior devem anualmente fazer prova da necessidade de um título que abranja mais do que uma CIM ou AM.
                            </p>
                        </Row>
                        <Row className="mt-5">
                            <h5 class="purple">PASSE <b>SOCIAL PART</b></h5>
                            <ul>
                                <li>Título <b>personalizado</b>, pessoal e intransmissível;</li>
                                <li>Vendido na Bilheteira;</li>
                                <li>Recarregado mensalmente na bilheteira ou na rede SaltPay;</li>
                                <li>Válido para o mês e Origem / Destino para o qual foi adquirido com número de viagens ilimitadas;</li>
                                <li>Tem de ser validado a bordo do autocarro;</li>
                                <li>Destina-se a residentes dos 8 concelhos que integram a CIM do Ave (Cabeceiras de Baste; Fafe; Guimarães; Mondim de Basto; Póvoa de Lanhoso; Vieira do Minho; Vizela; Vila Nova de Famalicão);</li>
                                <li>Desconto de 50% face ao preço do Passe Mensal.</li>
                            </ul>
                        </Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}